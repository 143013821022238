.career-bg {
    background: url(../../../public/Image/image3.png) fixed;
    padding-bottom: 100px;
    animation: animateProduct linear 30s infinite;
    animation-delay: 0s;
    animation-direction:normal;
    animation-fill-mode: none;
    animation-play-state: running;
  }
  
  @keyframes animateProduct {
      0%{
          background-position-x: 70%, 0px;
           background-position-y: 0px, 0px;
      }
      100%{
          background-position-x: -80%, 70%;
      background-position-y: 0px, 0px;
      }
  }

  .career-heading {
    color: orange;
    margin-top: 150px;
    font-size: 3rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:oblique;
  }

  .required-skills{
    color: #222f3e;
    text-align: justify;
    /* font-weight: bold; */
    text-align: justify;
}
.required-skills ul{
    list-style-type: none;
}

.form-content{
    border: 1px solid black;
    border-radius: 5px;
    padding:5px 10px;
    box-shadow: 0px 1px 5px 2px orange;
}
.form-group{
    margin-top: 10px;
}