.main{
  font-weight: 500;
}
.services-bg {
    background: url(../../../public/Image/image3.png) fixed;
    padding-bottom: 100px;
    animation: animateProduct linear 30s infinite;
    animation-delay: 0s;
    animation-direction:normal;
    animation-fill-mode: none;
    animation-play-state: running;
  }
  
  @keyframes animateProduct {
      0%{
          background-position-x: 70%, 0px;
           background-position-y: 0px, 0px;
      }
      100%{
          background-position-x: -80%, 70%;
      background-position-y: 0px, 0px;
      }
  }
  .service-heading {
    color: orange;
    margin-top: 150px;
    font-size: 3rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:oblique;
  }
  
  .service1 {
    color: #0c2461;
    font-weight: 600;
    /* margin-top: 100px; */
    /* text-align: justify; */
  }
 
  .service2{
    /* color: #0c2461; */
    color: white;
    font-weight: 600;
    margin-top: 50px;
    text-align: center;
  }

  .service2-image{
    margin-top: 100px;
  }
  .service3-image{
    margin-top: 140px;
  }
  .service4-image{
    margin-top: 250px;
  }
  /* .service2::after {
    content: "";
    position: absolute;
    left: 8%;
    margin-top: 40px;
    height: 3px;
    width: 390px;
    background: -webkit-linear-gradient(
      left,
      rgb(156, 4, 156) 0,
      rgb(156, 4, 156) 51%,
      rgb(156, 4, 156) 52%,
      rgb(156, 4, 156) 100%
    );
  } */

  .service3{
    margin-top: 100px;
    color: #0c2461;
    font-weight: 600;
  }
  .service4{
    margin-top: 100px;
    color: #0c2461;
    font-weight: 600;
  }
  .service-para {
    color: #222f3e;
    text-align: justify;
    margin-top: 20px;
  }

  .service-list{
    color: #222f3e;
    text-align: justify;
    /* font-weight: bold; */
    text-align: justify;
}
.service-list ul{
    list-style-type: none;
}
.service-list ul li::first-letter{
    color: red;
}

.mobile-app, .hosting{
  background: url(../../../public/Image/image2.jpg) fixed;
  padding-bottom: 40px;
}

  