body {
  font: 400 1em/1.5 "Neuton";
  /* background: #090d00;  */
  color: rgba(255, 255, 255, 0.25);
  text-align: center;
  margin: 0;
  font-family: 'Open Sans',sans-serif;
}

.start {
  /* background-color: #646363; */
  background-image: linear-gradient(to right, rgb(124, 17, 124),rgb(218, 9, 218));
}
.start a {
  color: white;
  font-size: 15px;
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  text-decoration: none;
}
.start .site a {
  padding-left: 20px;
}

.item {
  text-decoration: none;
  color:white;
  font-size: 17px;
  font-weight: 500;
  /* margin-top: 100px; */
}

ul li::first-letter{
  color: orange;
  font-size: 20px;
  /* transform: scale(2,2); */
}
.navbar {
  box-shadow: 1px -0px 4px 1px rgba(241, 238, 238, 0.45);
  background-image: linear-gradient(to right,rgb(194, 12, 194), rgb(124, 17, 124));
}
.navbar-nav {
  margin-left: auto;
}

.nav-item {
  margin: 0px 12px;
  color: black;
}

.nav-item:hover .item {
  color: orange;
}

.nav-item::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: white;
  transition: width .2s;
}

.nav-item:hover::after {
  width: 100%;
  color: orange;
}

.kiezenlogo {
  font: 700 1.6em/1 "Oswald", sans-serif;
  letter-spacing: 0;
  padding: 0.25em 0 0.325em;
  display: block;
  margin: 0 auto;
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);

  /* Clip Background Image */

  background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
  background-clip: text;

  /* Animate Background Image */

  -webkit-text-fill-color: transparent;
  animation: anim 80s linear infinite;

  /* Activate hardware acceleration for smoother animations */

  transform: translate3d(0, 0, 0);
  backface-visibility:hidden;
}

/* Animate Background Image */

@keyframes anim {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;       
  }
}

.kiezenlogo::after{
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: white;
  transition: width .3s;
}
.kiezenlogo:hover::after{
  width: 100%;
}