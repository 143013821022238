.contact-main{
    font-weight: 500;
}

.contact-bg {
    background: url(../../../public/Image/image3.png) fixed;
    padding-bottom: 100px;
    animation: animateProduct linear 30s infinite;
    animation-delay: 0s;
    animation-direction:normal;
    animation-fill-mode: none;
    animation-play-state: running;
  }
  
  @keyframes animateProduct {
      0%{
          background-position-x: 70%, 0px;
           background-position-y: 0px, 0px;
      }
      100%{
          background-position-x: -80%, 70%;
      background-position-y: 0px, 0px;
      }
  }

  .contact-heading {
    color: orange;
    margin-top: 150px;
    font-size: 3rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:oblique;
  }

  .contact{
    text-align: justify;
  }

  .contact span
{
    font-size: 13px;
}
.contact p
{
    font-size: 18px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.contact-form label
{
    font-size: 14px;
}
.contact-form input
{
    border: 1px solid black;
    border-radius: 0px;
}
.contact-form button[type=button]:hover
{
    color: white;
    background-color:blue;
}
.contact-form-details{
    color: black;
    text-align: justify;
}