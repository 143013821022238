
.kiezen1{
    margin-top: 20px;
    font-family: Fredericka the Great;
    color: white;
    font-size: 5rem;
}
.kiezen1::after{
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: white;
    transition: width 0.3s;
}
.kiezen1:hover::after{
    width: 100%;
}
.kiezen1:hover{
    color: orange;
}
.kiezen1:hover + .kiezen2{
    color: orange;
}
.kiezen2{
    line-height: 1.42857143;
    color: white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:oblique;
}
.kiezen-bg{
    position: relative;
    /* background: url(../../../public/Image/image2.jpg) repeat; */
    background: url(../../../public/Image/image3.png) fixed;
    animation: animateBackground linear 30s infinite;
    animation-delay: 0s;
    animation-direction:normal;
    animation-fill-mode: none;
    animation-play-state: running;
    padding-bottom: 100px;
   
}
@keyframes animateBackground {
    0%{
        background-position-x: 70%, 0px;
         background-position-y: 0px, 0px;
    }
    100%{
        background-position-x: -80%, 70%;
    background-position-y: 0px, 0px;
    }
}

.kiezen-bg img{
    width: 250px;
}
 
.who-we-are{
    margin-top: 80px;
    margin-bottom: 80px;
}

.kiezen3 h3 {
    /* font-size: 36px; */
    margin-bottom: 30px;
    /* padding-bottom: 10px; */
    position: relative;
    margin-top: 0;
    color: #0c2461;
}
.kiezen3 h3::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 5px;
    width: 60px;
    background: -webkit-linear-gradient(left,#0c2461 0,#0c2461 51%,#f6b93b 52%,#f6b93b 100%);
}

.service-bg{
    /* background: url(../../../public/Image/service1.webp); */
    background: url(../../../public/Image/image2.jpg) fixed; 
    padding-bottom: 50px;
}
.why-choose{
    color: #f6b93b;
    margin-top: 50px;
}
.why-icon i{
    color: #f6b93b;
    margin-top: 20px;
}
.why-icon i.lg {
    font-size: 32px;
    width: 65px;
    height: 65px;
    line-height: 65px;
    border-radius: 50%;
    border: 1px solid #f6b93b;
}

.card1, .card2, .card3, .card4{
    /* width: 250px; */
    box-shadow: 0 5px 0 0 orange;
}
.card1:hover{
    box-shadow: 1px 5px 3px 4px orange;
    transition: all .2s ease-in-out;
}

 .card3:hover{
    box-shadow: 1px 5px 3px 4px orange;
    transition: all .2s ease-in-out;
}
.card2:hover{
    box-shadow: 1px -5px 3px 4px orange;
    transition: all .2s ease-in-out;
}
 .card4:hover{
    box-shadow: 1px -5px 3px 4px #ffa500;
    transition: all .2s ease-in-out;
}
.card:hover h5{
    color:orange;
  cursor: pointer;
  text-decoration: underline;
}
.service-row1{
    margin-top: 120px;
}
.service-row2{
    margin-top: 120px;
}
.service-row3{
    margin-top: 80px;
}
.service-row4{
    margin-top: 80px;
}
.service-bg .row h2::after{
    content: '';
    position: absolute;
    margin-top: 45px;
    /* bottom: -5px; */
    left: 49%;
    height: 6px;
    width: 50px;
    background: -webkit-linear-gradient(left,#f6b93b 0,#f6b93b 51%,#f6b93b 52%,#f6b93b 100%);
}
.kiezen-services{
    margin-top: 20px;
    color: #0c2461;
    font-weight: 500;
}
.kiezen-services::after{
    content: '';
    position: absolute;
    margin-top: 45px;
    /* bottom: -5px; */
    left: 49%;
    height: 6px;
    width: 50px;
    background: -webkit-linear-gradient(left,#0c2461 0,#0c2461 51%,#f6b93b 52%,#f6b93b 100%);
}
.service-card .card{
    border-radius: 5px;
    border: 1px solid purple;
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}
.service-card .card:hover{
    background-position: left bottom;
    box-shadow: 0px 1px 7px 5px orange;
    transform: rotateY(-35deg);
}

.service-card .card:hover img{
    cursor: pointer;
}

.service-anim::after{
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: purple;
    transition: width 0.3s;
}
.service-anim:hover::after{
    width: 100%;
}
.message{
    color: black;
    margin-top: 50px;
    box-shadow: 0 3px 4px 0 rgba(39, 36, 36, 0.45);
}
.message img{
    transition: all .4s ease-out;
}
.message img:hover{
    transform: scale(1.1,1.1);
    box-shadow: 0px 1px 7px 8px rgb(238, 16, 238);
}
.name, .post{
    color: #0c2461;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;
}
.kiezen-missions{
    color: black;
    padding-bottom: 10px;
    box-shadow: 0 1px 5px 2px rgba(39, 36, 36, 0.45);
    background: url(../../../public/Image/image3.png) fixed;
}
.kiezen-missions img{
    background-color: white;
    width: 100%;
    margin-top: 35px;
}

.missions{
     color: orange;
     font-weight: 500;
     /* text-align: justify; */
     margin-top: 20px;
}
.missions::after{
    content: '';
    position: absolute;
    margin-top: 45px;
    /* bottom: -5px; */
    left: 49%;
    height: 6px;
    width: 50px;
    background: -webkit-linear-gradient(left,#0c2461 0,#0c2461 51%,#f6b93b 52%,#f6b93b 100%);
}
.missions-para{
    color: white;
    text-align: justify;
    margin-bottom: 50px;
    margin-top: 100px;
    line-height: 1.8rem;
}