footer
{
    /* background:url(../../../public/Image/service1.webp); */
    /* background:url(../../../public/Image/image3.png) fixed; */
    background-image: linear-gradient(to right,rgb(194, 12, 194), rgb(124, 17, 124));
    padding-top: 20px;
    color: white;
    padding-bottom: 10px;
}
.footer1 a
{
    text-decoration: none;
    color: white;
    font-size: 15px;
}
.footer1 a:hover
{
    color:orange;
}
.footer1 p
{
    font-size: 15px;
    text-align: justify;
}
.footer1 span
{
    font-size: 15px;
}
.our-service li{
    list-style-type: none;
    text-align: justify;
}
.our-service li::first-letter{
    font-size: 15px;
}
.footer1 h6{
    color: orange;
    font-weight: 600;
}