.about {
    box-sizing: border-box;
}
.about-us-bg{
    background: url(../../../public/Image/image2.jpg) fixed;
    padding-bottom: 40px;
    padding-top: 20px;
}

.about-bg {
    background: url(../../../public/Image/image3.png) fixed;
    padding-bottom: 100px;
    animation: animateProduct linear 30s infinite;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes animateProduct {
    0% {
        background-position-x: 70%, 0px;
        background-position-y: 0px, 0px;
    }

    100% {
        background-position-x: -80%, 70%;
        background-position-y: 0px, 0px;
    }
}

.about-heading {
    color: orange;
    margin-top: 150px;
    font-size: 3rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:oblique;
}

.about h3 {
    margin-bottom: 30px;
    position: relative;
    margin-top: 0;
    color: #0c2461;
}

.about h3::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 1%;
    height: 5px;
    width: 60px;
    background: -webkit-linear-gradient(left, #0c2461 0, #0c2461 51%, #f6b93b 52%, #f6b93b 100%);
}


.about-heads {
    font-size: 16px;
    color: rgb(247, 8, 247);
    font-weight: bold;
}

.card-box {
    border: 1px solid white;
    box-shadow: 0px 1px 5px 2px white;
    color: black;
    padding: 10px 12px;
    border-radius: 8px;
    background-color: white;
}

.card-box:hover {
    background-color: rgb(238, 16, 238);
    transition: all ease-in-out .5s;
}

.card-box:hover .about-heads {
    color: white;
}

.last-row {
    margin-top: 25px;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .card-box {
        height: 250px;
    }
}

@media screen and (max-width:768px) {
    .card-box,.project-card,.clients-box {
        margin: 10px;
    }

    .last-row {
        margin-top: 0px;
    }
}

.project-card {
    box-shadow: 0px 1px 7px 1px orange;
    border-radius: 5px;
    padding: 10px 0px;
    background: linear-gradient(to right, orange 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.project-card:hover{
    background-position: left bottom;
    box-shadow: 0px 1px 7px 3px rgb(238, 16, 238);
}
.project-card:hover p{
    color: white;
}
.project-card:hover h3{
    color: white
}
.project-card p{
    font-weight: 500;
    font-style: oblique;
}

.clients-box{
    border-radius: 10px;
    box-shadow: 0px 1px 7px 3px orange;
}

