.popup-button{
    position: fixed;
    top: 50%;
    right: 20px;
    left: auto;
    z-index: 1000;
    background-color: rgb(238, 9, 207);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
}
input[type=text],input[type=email]{
    border-radius: 5px;
    border: 1px solid purple;
}
.popup{
    border-radius: 8px;
    padding: 20px;
    color: black;
    background-color:skyblue;
    box-shadow:3px 2px 2px 2px black ;
    position: fixed;
    top: 25%;
    right: 90px;
    z-index: 1000;
}
.popup1{
    border-radius: 8px;
    padding: 20px;
    color: black;
    background-color:skyblue;
    box-shadow:3px 2px 2px 2px black ;
    position: fixed;
    top: 25%;
    right: 90px;
    z-index: 1000;
    height: 400px;
}
.popup-form{
    background-color: white;
    box-shadow:1px 1px 3px 2px purple ;
    padding: 25px;
    border-radius: 5px;
}

