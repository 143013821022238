.products-bg {
  background: url(../../../public/Image/image3.png) fixed;
  padding-bottom: 100px;
  animation: animateProduct linear 30s infinite;
  animation-delay: 0s;
  animation-direction:normal;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes animateProduct {
    0%{
        background-position-x: 70%, 0px;
         background-position-y: 0px, 0px;
    }
    100%{
        background-position-x: -80%, 70%;
    background-position-y: 0px, 0px;
    }
}
.product-head {
  color: orange;
  margin-top: 150px;
  font-size: 3rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:oblique;
}

.product1 {
  color: #0c2461;
  font-weight: 600;
  margin-top: 100px;
  text-align: justify;
}
.product1::after {
  content: "";
  position: absolute;
  left: 8%;
  margin-top: 40px;
  height: 2px;
  width: 140px;
  background: -webkit-linear-gradient(
    left,
    rgb(156, 4, 156) 0,
    rgb(156, 4, 156) 51%,
    rgb(156, 4, 156) 52%,
    rgb(156, 4, 156) 100%
  );
}
.product-para {
  color: #222f3e;
  text-align: justify;
  margin-top: 20px;
}

.list{
    color: #222f3e;
    text-align: justify;
}
.list ul{
    list-style-type: none;
}
.list ul li::first-letter{
    color: #0c2461;
}
